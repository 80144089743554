<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <section class="subHeader-content">
      <BlueHeader :itemsHeader="itemsHeader" />
  
      <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
      <v-slide-y-transition mode="out-in">
        <router-view></router-view>
      </v-slide-y-transition>
  
  
    </section>
  </template>
  
   <!-- #################################################################################### -->
   <!-- ###### Sección de Scripts                                                     ###### -->
   <!-- #################################################################################### -->
  <script>
  import { Role } from "@/router/role.js";
  import BlueHeader from "../../../../components/BlueHeader.vue";
  
  export default {
    name: 'AutoimmuneMesh',
    components: { BlueHeader },
  
    data: () => ({
      tab: null,
      roles: Role,
      userRoles: {},
      itemsHeader: [
        {
          name: 'Malla Autoinmunes',
          link: { name: 'modules.validatorMeshMain.AutoimmunesMesh.cargarMalla' },
          rol: Role.ServiciosFarmaceuticos_MallaAutoinmunes_CargarMalla,
        },
        {
          name: 'Carga por periodos IPS',
          link: { name: 'modules.validatorMeshMain.AutoimmunesMesh.generarArchivoPlano' },
          rol: Role.ServiciosFarmaceuticos_MallaAutoinmunes_CargarMalla,
        }
      ]
    }),
  }
  </script>
  
   <!-- #################################################################################### -->
   <!-- ###### Sección de Styles                                                      ###### -->
   <!-- #################################################################################### -->
  <style scoped>
  .subHeader-content {
    width: 100%;
  }
  </style>